/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// multi date picker
import DatePicker from "react-multi-date-picker";

//import date format from helper folder
import { formatDate } from "helpers/DateCalculation";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/* Importing react select */
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

//import options from json file
import ReserveStatus from "data/Prod/ReserveStatus.json";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { postData, getData, putData, deleteData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

const ReserveFilter = ({
  setColumns,
  filterTitle,
  setFilterTitle,
  filterJsonData,
  setfilterJsonData,
  filterId,
  setFilterId,
  filterDefault,
  setFilterDefault,
  setReloadFilterList,
  resetFilterData,
  setResetFilterData,
  isSearchExecute,
  setIsSearchExecute,
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const todayValue = new Date().toISOString().split("T")[0];

  const {
    // searchReserveDate,
    setSearchReserveDate,
    setSearchReserveIdList,
    setSearchReserveAmbIdList,
    setSearchReserveActivity,
    setSearchReserveLabels,
    setSearchReserveBenificiaries,
    setSearchReserveClients,
    setSearchReserveExcludeClients,
    setSearchReserveEducators,
    setSearchReserveStatus,
    setisConsecutiveReserveFilter,
    setIsTemplateReserveFilter,
    setUnsentMailReserveFilter,
    setIsDefaultReserveFilter,
    setReloadReservationList,
    // reloadRefresh,
    // setReloadRefresh,
  } = useContext(GlobalProvider);

  //options for select reserve columns
  const dataColumns = [
    //{ label: t("ID"), value: "ID" },
    // { label: t("Is Consecutive"), value: "IsConsecutive" },
    { label: t("Shift"), value: "Shift" },
    { label: t("AMB ID"), value: "AMB ID" },
    { label: t("Date"), value: "Date" },
    { label: t("Day"), value: "Day" },
    { label: t("Start Time"), value: "Start Time" },
    { label: t("End Time"), value: "End Time" },
    { label: t("Code"), value: "Code" },
    { label: t("Activity"), value: "Activity" },
    { label: t("Activity Type"), value: "Activity Type" },
    { label: t("Tags"), value: "Tags" },
    { label: t("Educator 1"), value: "Educator 1" },
    { label: t("Educator 2"), value: "Educator 2" },
    { label: t("Client"), value: "Client" },
    { label: t("Observer"), value: "Observer" },
    { label: t("Beneficiary"), value: "Beneficiary" },
    { label: t("Responsible"), value: "Responsible" },
    { label: t("No of Participants"), value: "No of Participants" },
    { label: t("No of Educator"), value: "No of Educator" },
    { label: t("No Participants Real"), value: "No Participants Real" },
    { label: t("Tutor"), value: "Tutor" },
    { label: t("Specific Audience"), value: "Specific Audience" },
    { label: t("Grade"), value: "Grade" },
    { label: t("Sales Order"), value: "Sales Order" },
    { label: t("Comments"), value: "Comments" },
    { label: t("Internal Comments"), value: "InternalComments" },
    { label: t("Location"), value: "Location" },
    { label: t("Zipcode"), value: "Zipcode" },
    { label: t("Status"), value: "Status" },
    //{ label: t("Reconfirmation"), value: "Reconfirmation" },
  ];

  const [labelList, setlabelList] = useState([]); // for label list
  const [activityList, setActivityList] = useState([]); // for activity list

  const [educatorList, setEducatorList] = useState([]); //educator option
  const [clientList, setClientList] = useState([]); //client option
  const [benificiaryList, setBenificiaryList] = useState([]); //benificiary option

  const [isConseCutive, setIsConseCutive] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [unsentMail, setUnsentMail] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const [filterName, setFilterName] = useState("");

  //use state for select filter
  // const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  const [selectedDatesPick, setSelectedDatesPick] = useState([]);

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  const [selectedFilterData, setSelectedFilterData] = useState({
    showColumnValue: null,
    showSelectedColumns: [],
    selectedRadioName: "",
    fromDate: "",
    toDate: "",
    selectedDate: "",
    reserveIdValue: null,
    reserveSelectedIds: [],
    reserveAmbIdValue: null,
    reserveSelectedAmbIds: [],
    reserveStatusValue: null,
    reserveStatusOption: [],
    mailValue: null,
    mailOptions: null,
    benificiaryValue: null,
    benificiaryOptions: [],
    activityValue: null,
    activityOptions: [],
    labelValue: null,
    labelOptions: [],
    clientValue: null,
    clientOptions: [],
    excludedClientValue: null,
    excludedClientOptions: [],
    educatorValue: null,
    educatorOptions: [],
    isConsecutive: false,
    isTemplate: false,
    unsentMail: false,
  });

  /* selecting options ends */

  //function for select radio
  const radioCheckHandler = (e) => {
    setDateWarning(false);
    setSelectedFilterData((prevData) => ({
      ...prevData,
      selectedRadioName: e.target.value,
    }));
  };

  /* function to get all activity */
  const getAllActivity = async () => {
    let requestUrl =
      url.API_BASEURL +
      url.API_GET_ALL_ACTIVITY +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setActivityList(response.data);
    }
  };

  /* function to get all client list */
  const getALlClientList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=client`;

      const response = await getData(requestUrl);

      if (response.status) {
        setClientList(response.data);
        setBenificiaryList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* function to get all client list */
  const getALlEducatorList = async () => {
    try {
      let requestUrl =
        url.API_BASEURL +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&slug=educator`;

      const response = await getData(requestUrl);

      if (response.status) {
        setEducatorList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* function to get all labels */
  const getAllLabels = async () => {
    let requestUrl =
      url.API_BASEURL +
      url.API_GET_ALL_TAG +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setlabelList(response.data);
    }
  };

  const datePickerChangeHandler = (clickdate) => {
    // Uncomment the following line if you want to log the clickdate
    // console.log("clickdate", clickdate);

    let currentDateSelected = ""; // Will hold the formatted dates as a string
    let currentDateArr = []; // Will hold the formatted dates as an array

    if (clickdate) {
      // Convert clicked dates to an array of formatted date strings
      currentDateArr = clickdate.map((date) => formatDate(new Date(date)));

      // Creating a comma-separated string of formatted dates
      clickdate.map((date, index) => {
        if (currentDateSelected) {
          currentDateSelected =
            currentDateSelected + "," + formatDate(new Date(date));
        } else {
          currentDateSelected = formatDate(new Date(date));
        }
      });
    }

    setSelectedDatesPick(currentDateArr); // Updating selected dates as an array

    // Updating selectedFilterData state
    setSelectedFilterData((prevData) => ({
      ...prevData,
      selectedRadioName: "eventdate",
      selectedDate: currentDateSelected,
      fromDate: "",
      toDate: "",
    }));

    // Uncomment the following line if you want to log the selectedDate
    // console.log("selectedDate", currentDateSelected);
  };

  // reset selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      try {
        let requestUrl =
          url.API_BASEURL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          setFilterName("");
          setFilterTitle("");
          setfilterJsonData(null);
          setFilterId(null);
          setFilterDefault(false);
          setReloadFilterList(true);
          resetFilterHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    // resetHandler();
  };

  /* function to save reservation */
  const saveWithFilterSearchHandler = async () => {
    let filterData = {
      name: filterName,
      parameters: JSON.stringify(selectedFilterData),
      moduleslug: "reserve",
      isdefault: isDefault,
    };

    // console.log(`filterData ==========>>>`, filterData);

    try {
      // if selectedFilterId then edit,

      // else add new
      let requestUrl = url.API_BASEURL;

      if (filterId && filterName !== "") {
        requestUrl =
          requestUrl +
          url.API_UPDATE_FILTER +
          `/${filterId}` +
          `?token=${token}`;
      } else {
        requestUrl = requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
      }

      const response = filterId
        ? await putData(requestUrl, filterData)
        : await postData(requestUrl, filterData);

      // console.log(`filterData response ==========>>>`, response);

      if (response.status) {
        searchHandler();
        setReloadFilterList(true);
      }
    } catch (error) {
      console.error(error.message);
    }

    closeFilterBlock();
  };

  //function for reset other component states
  const resetOtherComponentStates = () => {
    setColumns([]);
    setSearchReserveDate("");
    setSearchReserveIdList([]);
    setSearchReserveAmbIdList([]);
    setSearchReserveActivity([]);
    setSearchReserveLabels([]);
    setSearchReserveBenificiaries([]);
    setSearchReserveClients([]);
    setSearchReserveEducators([]);
    setSearchReserveStatus([]);
    setisConsecutiveReserveFilter(false);
    setIsTemplateReserveFilter(false);
    setUnsentMailReserveFilter(false);
  };

  //function for search
  const searchHandler = () => {
    // Extract selected column values or reset columns
    if (selectedFilterData.showColumnValue) {
      const selectedColumnValues = selectedFilterData.showColumnValue.map(
        (item) => item.value
      );
      setColumns(selectedColumnValues); // Update columns state based on selected column values
    } else {
      setColumns([]); // Reset columns state
    }

    /*===== switch statement for date input radio start ======*/
    switch (selectedFilterData.selectedRadioName) {
      case "daterange":
        if (selectedFilterData.fromDate < selectedFilterData.toDate) {
          setSearchReserveDate(
            selectedFilterData.fromDate + "|" + selectedFilterData.toDate
          ); // Set searchReserveDate for date range
        } else {
          setDateWarning(true); // Show date warning
        }
        break;

      case "todayradio":
        setSearchReserveDate(todayValue); // Set searchReserveDate for today
        break;

      case "eventdate":
        setSearchReserveDate(selectedFilterData.selectedDate); // Set searchReserveDate for event date
        break;

      default:
        setSearchReserveDate(""); // Reset searchReserveDate
        break;
    }
    /*===== switch statement for date input radio end ======*/

    // Assign data for search based on selected options

    // Update searchReserveIdList based on selected reservation IDs
    selectedFilterData.reserveSelectedIds.length > 0
      ? setSearchReserveIdList(selectedFilterData.reserveSelectedIds)
      : setSearchReserveIdList([]);

    // Update searchReserveAmbIdList based on selected reservation Amb IDs
    selectedFilterData?.reserveSelectedAmbIds?.length > 0
      ? setSearchReserveAmbIdList(selectedFilterData.reserveSelectedAmbIds)
      : setSearchReserveAmbIdList([]);

    // Update searchReserveActivity based on selected activity options
    selectedFilterData.activityOptions.length > 0
      ? setSearchReserveActivity(selectedFilterData.activityOptions)
      : setSearchReserveActivity([]);

    // Update searchReserveLabels based on selected label options
    selectedFilterData.labelOptions.length > 0
      ? setSearchReserveLabels(selectedFilterData.labelOptions)
      : setSearchReserveLabels([]);

    // Update searchReserveBenificiaries based on selected beneficiary options
    selectedFilterData.benificiaryOptions.length > 0
      ? setSearchReserveBenificiaries(selectedFilterData.benificiaryOptions)
      : setSearchReserveBenificiaries([]);

    // Update searchReserveClients based on selected client options
    selectedFilterData.clientOptions.length > 0
      ? setSearchReserveClients(selectedFilterData.clientOptions)
      : setSearchReserveClients([]);

    //update search exclude clients
    selectedFilterData.excludedClientOptions.length > 0
      ? setSearchReserveExcludeClients(selectedFilterData.excludedClientOptions)
      : setSearchReserveExcludeClients([]);

    // Update searchReserveEducators based on selected educator options
    selectedFilterData.educatorOptions.length > 0
      ? setSearchReserveEducators(selectedFilterData.educatorOptions)
      : setSearchReserveEducators([]);

    // Update searchReserveStatus based on selected reserve status options
    selectedFilterData.reserveStatusOption.length > 0
      ? setSearchReserveStatus(selectedFilterData.reserveStatusOption)
      : setSearchReserveStatus([]);

    // Set filters for consecutive and template reserves
    setisConsecutiveReserveFilter(selectedFilterData.isConsecutive); // Update isConsecutiveReserveFilter state
    setIsTemplateReserveFilter(selectedFilterData.isTemplate); // Update isTemplateReserveFilter state
    setUnsentMailReserveFilter(selectedFilterData.unsentMail);
    setIsDefaultReserveFilter(selectedFilterData.isDefault);

    closeFilterBlock();
  };

  //function for reset filter
  const resetFilterHandler = () => {
    setFilterName("");
    setIsConseCutive(false);
    setIsTemplate(false);
    setSelectedDatesPick([]);
    setSelectedFilterData({
      ...selectedFilterData,
      showColumnValue: null,
      showSelectedColumns: [],
      selectedRadioName: "",
      fromDate: "",
      toDate: "",
      selectedDate: "",
      reserveIdValue: null,
      reserveSelectedIds: [],
      reserveAmbIdValue: null,
      reserveSelectedAmbIds: [],
      reserveStatusValue: null,
      reserveStatusOption: [],
      mailValue: null,
      mailOptions: null,
      benificiaryValue: null,
      benificiaryOptions: [],
      activityValue: null,
      activityOptions: [],
      labelValue: null,
      labelOptions: [],
      clientValue: null,
      clientOptions: [],
      excludedClientOptions: [],
      excludedClientValue: null,
      educatorValue: null,
      educatorOptions: [],
    });
    setIsDefault(false);
    resetOtherComponentStates();
    //closeFilterBlock();
    setReloadReservationList(true);
  };

  //function for close filter collapse
  const closeFilterBlock = () => {
    // toggle the filter block
    var filterCollapseDiv = document.getElementById("filterCollapse");
    // Remove the class from the div element
    filterCollapseDiv.classList.remove("show");
  };

  // useEffect(() => {
  //   if (reloadRefresh) {
  //     resetHandler();
  //     setReloadRefresh(false);
  //   }
  // }, [reloadRefresh]);

  //function for default filter
  const defaultFilterHandler = async () => {
    try {
      let requestUrl =
        url.API_BASEURL + url.API_GET_DEFAULT_FILTER + `?token=${token}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          let filterData = response.data;

          if (filterData.isdefault) {
            const jsonFilterData = JSON.parse(filterData.parameters);

            // Extract selected column values or reset columns
            if (jsonFilterData.showColumnValue) {
              const selectedColumnValues = jsonFilterData.showColumnValue.map(
                (item) => item.value
              );
              setColumns(selectedColumnValues); // Update columns state based on selected column values
            } else {
              setColumns([]); // Reset columns state
            }

            /*===== switch statement for date input radio start ======*/
            switch (jsonFilterData.selectedRadioName) {
              case "daterange":
                if (jsonFilterData.fromDate < jsonFilterData.toDate) {
                  setSearchReserveDate(
                    jsonFilterData.fromDate + "|" + jsonFilterData.toDate
                  ); // Set searchReserveDate for date range
                } else {
                  setDateWarning(true); // Show date warning
                }
                break;

              case "todayradio":
                setSearchReserveDate(todayValue); // Set searchReserveDate for today
                break;

              case "eventdate":
                setSearchReserveDate(jsonFilterData.selectedDate); // Set searchReserveDate for event date
                break;

              default:
                setSearchReserveDate(""); // Reset searchReserveDate
                break;
            }
            /*===== switch statement for date input radio end ======*/

            // Assign data for search based on selected options

            // Update searchReserveIdList based on selected reservation IDs
            jsonFilterData.reserveSelectedIds.length > 0 &&
              setSearchReserveIdList(jsonFilterData.reserveSelectedIds);

            // Reset searchReserveIdList if no reservation IDs are selected
            jsonFilterData.reserveSelectedIds.length == 0 &&
              setSearchReserveIdList([]);

            // Update searchReserveActivity based on selected activity options
            jsonFilterData.activityOptions.length > 0 &&
              setSearchReserveActivity(jsonFilterData.activityOptions);

            // Update searchReserveLabels based on selected label options
            selectedFilterData.labelOptions.length > 0 &&
              setSearchReserveLabels(jsonFilterData.labelOptions);

            // Update searchReserveBenificiaries based on selected beneficiary options
            jsonFilterData.benificiaryOptions.length > 0 &&
              setSearchReserveBenificiaries(jsonFilterData.benificiaryOptions);

            // Update searchReserveClients based on selected client options
            jsonFilterData.clientOptions.length > 0 &&
              setSearchReserveClients(jsonFilterData.clientOptions);

            //update exclude client
            jsonFilterData.excludedClientOptions.length > 0 &&
              setSearchReserveClients(jsonFilterData.excludedClientOptions);

            // Update searchReserveEducators based on selected educator options
            jsonFilterData.educatorOptions.length > 0 &&
              setSearchReserveEducators(jsonFilterData.educatorOptions);

            // Update searchReserveStatus based on selected reserve status options
            jsonFilterData.reserveStatusOption.length > 0 &&
              setSearchReserveStatus(jsonFilterData.reserveStatusOption);

            // Set filters for consecutive and template reserves
            setisConsecutiveReserveFilter(jsonFilterData.isConsecutive); // Update isConsecutiveReserveFilter state
            setUnsentMailReserveFilter(jsonFilterData.unsentMail);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (isSearchExecute) {
      searchHandler();
      setIsSearchExecute(false);
    }
  }, [isSearchExecute]);

  useEffect(() => {
    if (resetFilterData) {
      resetFilterHandler();
      setResetFilterData(false);
    }
  }, [resetFilterData]);

  useEffect(() => {
    if (filterTitle !== "" && filterJsonData && filterId) {
      setFilterName(filterTitle);
      setIsDefault(filterDefault);
      setSelectedFilterData(filterJsonData);

      //assign dates in Date picker
      if (filterJsonData.selectedDate != "") {
        // Simulated API response string
        const apiDateString = filterJsonData.selectedDate;

        // Split the API response string into individual date strings
        const dateStrings = apiDateString.split(",");

        // Convert date strings to JavaScript Date objects
        const parsedDates = dateStrings.map((dateString) => {
          const [day, month, year] = dateString.split("-");
          return new Date(year, month - 1, day); // Month is 0-based in Date object
        });

        // Set the parsed dates as the initial selected dates
        setSelectedDatesPick(parsedDates);
      } else {
        setSelectedDatesPick([]);
      }
    }
  }, [filterTitle, filterJsonData, filterId, filterDefault]);

  useEffect(() => {
    getAllLabels();
    getAllActivity();
    getALlClientList();
    getALlEducatorList();
    defaultFilterHandler();
  }, []);

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t("Filter")}</h4>
        {/* ------- search form section start ------- */}
        <form onSubmit={(e) => e.preventDefault()}>
          {/* ------ select filter and save new filter input section start ---- */}
          <div className="form-group mb-1">
            <div className="row">
              <div className="col-lg-6">
                {/* ------Save as new Filter Name -------- */}
                <div className="form-group mb-3 d-flex align-items-center">
                  <label className="col-lg-3 mt-1">
                    {t("Save as new filter")}
                  </label>
                  <input
                    type="text"
                    id="filtername"
                    className="form-control"
                    placeholder={t("Add filter name")}
                    value={filterName}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                  <Link
                    to="#"
                    className="filter-btn btn icon-btn mw-unset"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Reset Filters"
                    onClick={deleteSelectedFilter}
                  >
                    <span className="d-block material-symbols-outlined">
                      delete
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* ------ select filter and save new filter input section end ---- */}

          <hr className="mb-3" />

          {/* ------ select column section start ---- */}
          <div className="form-container d-flex flex-wrap gap-2 mb-3">
            <div className="form-group flex-fill d-flex align-items-center">
              <label className="col-lg-2 mt-2">
                {" "}
                {t("Select columns to show")}{" "}
              </label>
              <Select
                placeholder={t("Select columns")}
                className="nosearch_select col-lg-10"
                isMulti
                options={dataColumns}
                value={selectedFilterData.showColumnValue}
                onChange={(val) => {
                  setSelectedFilterData((prevData) => ({
                    ...prevData,
                    showColumnValue: val,
                    showSelectedColumns: val.map((label) => label.value),
                  }));
                }}
              />
            </div>
          </div>
          {/* ------ select column section end ---- */}

          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={
                        selectedFilterData.selectedRadioName === "daterange"
                      }
                      onChange={radioCheckHandler}
                    />
                    <label
                      className="form-check-label mb-0 me-3"
                      htmlFor="today"
                    >
                      {t("Date Range")}
                    </label>
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t("From date")}
                      value={selectedFilterData.fromDate}
                      onChange={(e) => {
                        // setFromDate(e.target.value);
                        // setSelectedChecked("daterange");
                        setSelectedDatesPick([]);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          selectedRadioName: "daterange",
                          fromDate: e.target.value,
                          selectedDate: "",
                        }));
                        setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t("To date")}
                      value={selectedFilterData.toDate}
                      onChange={(e) => {
                        // setToDate(e.target.value);
                        // setSelectedChecked("daterange");
                        setSelectedDatesPick([]);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          toDate: e.target.value,
                          selectedRadioName: "daterange",
                          selectedDate: "",
                        }));
                        setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>

                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End date should be greater than start date")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={
                      selectedFilterData.selectedRadioName === "todayradio"
                    }
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t("Today")}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eventdate"
                      id="multipleDates"
                      value="eventdate"
                      checked={
                        selectedFilterData.selectedRadioName === "eventdate"
                      }
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <label className="form-check-label mb-0 me-3" htmlFor="today">
                    {t("Multiple Dates")}
                  </label>
                  <DatePicker
                    className="form-control"
                    multiple
                    value={selectedDatesPick}
                    onChange={datePickerChangeHandler}
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ id selection section start -------- */}
              <div className="col-lg-2 mb-3 mb-lg-0">
                <CreatableSelect
                  isMulti
                  placeholder={t("Customer ID")}
                  value={selectedFilterData.reserveIdValue}
                  onChange={(val) =>
                    setSelectedFilterData((prevData) => ({
                      ...prevData,
                      reserveIdValue: val,
                      reserveSelectedIds: val.map((label) => label.value),
                    }))
                  }
                />
              </div>
              {/* ------ id selection section end -------- */}

              {/* ------ amb id selection section start -------- */}
              <div className="col-lg-2 mb-3 mb-lg-0">
                <CreatableSelect
                  isMulti
                  placeholder={t("Amb Id")}
                  value={selectedFilterData.reserveAmbIdValue}
                  onChange={(val) =>
                    setSelectedFilterData((prevData) => ({
                      ...prevData,
                      reserveAmbIdValue: val,
                      reserveSelectedAmbIds: val.map((label) => label.value),
                    }))
                  }
                />
              </div>
              {/* ------ amb id selection section end -------- */}

              {/* ------ activity selection section start -------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t("Select activity")}
                    className="nosearch_select"
                    options={activityList}
                    isMulti
                    value={selectedFilterData.activityValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        activityValue: val,
                        activityOptions: val.map((label) => label.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ activity selection section end -------- */}

              {/* ------ label selection section start -------- */}
              <div className="col-lg-4">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t("Select label")}
                    className="nosearch_select"
                    options={labelList}
                    isMulti
                    value={selectedFilterData.labelValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        labelValue: val,
                        labelOptions: val.map((label) => label.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ label selection section end -------- */}
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ benificiary selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t("Select beneficiary")}
                    className="nosearch_select"
                    options={benificiaryList}
                    isMulti
                    value={selectedFilterData.benificiaryValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        benificiaryValue: val,
                        benificiaryOptions: val.map(
                          (benificiary) => benificiary.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ benificiary selection section end -------- */}

              {/* ------ client selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t("Include clients")}
                    isMulti
                    options={clientList}
                    value={selectedFilterData.clientValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        clientValue: val,
                        clientOptions: val.map((client) => client.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ client selection section end -------- */}

              {/* ------ exclude client selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t("Exclude clients")}
                    isMulti
                    options={clientList}
                    value={selectedFilterData.excludedClientValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        excludedClientValue: val,
                        excludedClientOptions: val.map(
                          (client) => client.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ exclude client selection section end -------- */}

              {/* ------ educator selection section start -------- */}
              <div className="col-lg-3">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t("Select educators")}
                    isMulti
                    options={educatorList}
                    value={selectedFilterData.educatorValue}
                    onChange={(val) =>
                      setSelectedFilterData((prevData) => ({
                        ...prevData,
                        educatorValue: val,
                        educatorOptions: val.map((educator) => educator.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ educator selection section end -------- */}
            </div>
          </div>
          <div className="form-group">
            <div className="row d-flex align-items-center">
              {/* ------ status selection section start -------- */}
              <div className="col-lg-2 mb-3 mb-lg-0">
                <Select
                  placeholder={t("Status")}
                  className="nosearch_select"
                  options={ReserveStatus}
                  isMulti
                  value={selectedFilterData.reserveStatusValue}
                  onChange={(val) =>
                    setSelectedFilterData((prevData) => ({
                      ...prevData,
                      reserveStatusValue: val,
                      reserveStatusOption: val.map((status) => status.value),
                    }))
                  }
                />
              </div>
              {/* ------ status selection section end -------- */}

              <div className="col-lg-10">
                <div className="row">
                  {/* ------ consecutive checkbox section start -------- */}
                  <div className="col-lg-3 mb-3 mb-lg-0 form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectedFilterData.isConsecutive}
                      onChange={(e) => {
                        // setIsConseCutive(e.target.checked);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          isConsecutive: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="saveAsReserveTemplate"
                    >
                      {t("Show Consecutive Reserves")}
                    </label>
                  </div>
                  {/* ------ consecutive checkbox section end -------- */}

                  {/* ------ Template checkbox section start -------- */}
                  {/* <div className="col-lg-3 mb-3 mb-lg-0 form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectedFilterData.isTemplate}
                      onChange={(e) => {
                        // setIsTemplate(e.target.checked);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          isTemplate: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="saveAsReserveTemplate"
                    >
                      {t("Show Reserve Templates")}
                    </label>
                  </div> */}
                  {/* ------ Template checkbox section end -------- */}

                  {/* ------ Unsent Mail checkbox section start -------- */}
                  <div className="col-lg-3 mb-3 mb-lg-0 form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectedFilterData.unsentMail}
                      onChange={(e) => {
                        // setUnsentMail(e.target.checked);
                        setSelectedFilterData((prevData) => ({
                          ...prevData,
                          unsentMail: e.target.checked,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="Unsent mail"
                    >
                      {t("Unsent mail")}
                    </label>
                  </div>
                  {/* ------ Unsent Mail checkbox section end -------- */}

                  {/* ------ isdefault checkbox section start -------- */}
                  <div className="col-lg-3 mb-3 mb-lg-0 form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isDefault}
                      onChange={(e) => {
                        setIsDefault(e.target.checked);
                      }}
                    />
                    <label className="form-check-label mb-0" htmlFor="Default">
                      {t("Is Default")}
                    </label>
                  </div>
                  {/* ------ isdefault checkbox section end -------- */}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                {/* ------ button section start -------- */}
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  {/* ------- reset button start ------- */}
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center btn btn-warning text-white"
                    onClick={resetFilterHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t("Clear")}</span>
                  </Link>
                  {/* ------- reset button end ------- */}

                  {/* ------- search button start ------- */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t("Filter")}
                  </button>
                  {/* ------- search button start ------- */}

                  {/* ------- search button start ------- */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveWithFilterSearchHandler}
                  >
                    {t("Save & Filter")}
                  </button>
                  {/* ------- search button start ------- */}
                </div>
                {/* ------ button section end -------- */}
              </div>
            </div>
          </div>
        </form>
        {/* ------- search form section end ------- */}
      </div>
    </div>
  );
};

export default ReserveFilter;
